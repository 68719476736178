import { useState, useEffect, useContext } from "react";
import { useForm } from "react-hook-form";
import { Button } from '@mui/material';
import { FormInputText } from '../../Form/FormInputText';
import { FormButtonSubmit } from "../../Form/FormButtonSubmit";
import { FormCheckbox } from "../../Form/FormCheckbox";
import { createMenu, getListPage, getOneMenu } from '../../../store/index.service';
import { FormAutocomplete } from "../../Form/FormAutocomplete";
import { StoreContext } from "../../../store/store";
import { NotificationManager } from "react-notifications";

export const AddMenu = ({ handleClosePopup, refreshData = () => {}, type }) => {

    const { handleSubmit, control, watch } = useForm();

    const { lang } = useContext(StoreContext);

    const [loading, setLoading] = useState(false);
    // const [errorMsg, setErrorMsg] = useState("");
    const [listPage, setListPage] = useState({ loading: true, data: [] });
    const [listMenu, setListMenu] = useState({ loading: true, data: [] });

    useEffect(() => {
        getListPage().then(res => {
            setListPage(res.page);
        });

        getOneMenu(type).then(res => {
            setListMenu(res.menu);
        });
    }, []);

    const onSubmit = data => {
        setLoading(true);
        const formData = new FormData();
        
        for (const [key, value] of Object.entries(data)) {
            if(key === "parents" && value)
                formData.append(key, value.id);
            else if(typeof value === "object")
                formData.append(key, JSON.stringify(value));
            else
                formData.append(key, value);
        }

        createMenu(formData, type)
            .then(() => {
                handleClosePopup();
                refreshData();
                NotificationManager.success("", "Lien ajouté au menu");
            })
            .catch(err => {
                console.error(err);
                setLoading(false);
                NotificationManager.error("", "Une erreur est survenue");

                // if(err.response.status === 409)
                //     setErrorMsg(err.response.data.result);
                // else
                //     setErrorMsg("Une erreur est survenue");
            })
    }

    return (
        <div className='popupCreateHorseQuick'>

            <h2>Ajoutez un lien au menu</h2>

            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form">
                    
                    <div className="row-form">
                        <FormInputText
                            name="title"
                            label="Titre"
                            control={control}
                            defaultValue="titre"
                            rules={{ required: true }}
                        />
                    </div>

                    <div className="row-form">
                        <FormCheckbox
                            control={control}
                            data={[
                                {
                                    name: "externalLink",
                                    label: "Lien externe"
                                }
                            ]}
                        />
                    </div>

                    <div className="row-form">
                        
                        {watch("externalLink") ?
                            <FormInputText
                                name="url"
                                label="URL"
                                control={control}
                                defaultValue="lien"
                            />
                        :
                            <FormAutocomplete
                                name="page"
                                control={control}
                                label="Page"
                                options={listPage}
                                getOptionLabel={el => el[lang].title.data}
                                renderOption={(props, option) =>
                                    <li {...props} key={option[lang].title.data}>
                                        {option[lang].title.data}
                                    </li>
                                }
                            />
                        }

                    </div>

                    <div className="row-form">
                        <FormAutocomplete
                            name="parents"
                            control={control}
                            label="Liste menu"
                            options={listMenu}
                            getOptionLabel={el => el[lang].title}
                            renderOption={(props, option) =>
                                <li {...props} key={option[lang].title}>
                                    {option[lang].title}
                                </li>
                            }
                        />
                    </div>

                </div>

                <div className="btn-container">

                    <Button 
                        variant="text"
                        color="grey"
                        size="small"
                        className="cancel"
                        onClick={handleClosePopup}
                    >
                        Annuler
                    </Button>

                    <FormButtonSubmit loading={loading} />

                </div>
            </form>
        </div>
    )
}