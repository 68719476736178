import { FormControlLabel, Switch } from "@mui/material";
import { Controller } from "react-hook-form";

export const FormSwitch = (props) => {

    const {
        control,
        name,
        label,
        labelPlacement,
        sx
    } = props;

    return (
        <FormControlLabel
            control={
                <Controller
                    name={name}
                    control={control}
                    render={({ field: { onChange, value } }) =>
                        <Switch
                            checked={!!value}
                            onChange={onChange}
                        />
                    }
                />
            }
            label={label}
            labelPlacement={labelPlacement}
            sx={sx}
        />
    );
};