import { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { FormInputText } from '../../Form/FormInputText';
import { FormButtonSubmit } from "../../Form/FormButtonSubmit";
import { createTemplate } from '../../../store/index.service';

export const CreateTemplate = ({ handleClosePopup, refreshData }) => {
    const navigate = useNavigate();
    const { handleSubmit, control, setValue, getValues, watch } = useForm();
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    const onSubmit = data => {

        setLoading(true);

        const formData = new FormData();
        
        for (const [key, value] of Object.entries(data)) {
            formData.append(key, value);
        }

        createTemplate(formData)
            .then(() => {
                refreshData();
                handleClosePopup();
            })
            .catch(err => {
                console.error(err);
                setLoading(false);

                if(err.response.status === 409)
                    setErrorMsg(err.response.data.result);
                else
                    setErrorMsg("Une erreur est survenue");
            })
    }

    return (
        <div className='popupCreateHorseQuick'>

            <h2>Créer un template</h2>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form">
                    
                    <div className="row-form">
                        <FormInputText
                            name="title"
                            label="titre"
                            control={control}
                            defaultValue="titre"
                            rules={{ required: true }}
                        />
                    </div>

                </div>

                <div className="btn-container">

                    <Button 
                        variant="text"
                        color="grey"
                        size="small"
                        className="cancel"
                        onClick={handleClosePopup}
                    >
                        Annuler
                    </Button>

                    <FormButtonSubmit loading={loading} />

                </div>
            </form>
        </div>
    )
}