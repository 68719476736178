import { useState, useEffect } from "react";
import { getListTemplate, deleteTemplate } from '../../../store/index.service';
import { HeaderList } from '../../../components/HeaderList/HeaderList';
import { Breadcrumb } from '../../../components/Breadcrumb/Breadcrumb';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTableColumns, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import { DataGridPro, GridActionsCellItem, GridToolbarContainer, GridToolbarFilterButton } from '@mui/x-data-grid-pro';
import { Popup } from "../../../components/Popup/Popup";
import { ConfirmDelete } from "../../../components/Popup/content/ConfirmDelete";
import { CreateTemplate } from "../../../components/Popup/section/CreateTemplate";
import { UpdateTemplate } from "../../../components/Popup/section/UpdateTemplate";
import { Button } from "@mui/material";

const CustomToolbar = () => {
    return (
        <GridToolbarContainer>
            <GridToolbarFilterButton />
        </GridToolbarContainer>
    );
}

export const Template = () => {

    const [listTemplate, setListTemplate] = useState({
        loading: true,
        data: []
    });

    const [showPopupConfirmDelete, setShowPopupConfirmDelete] = useState({show: false});
    const [showPopupCreateTemplate, setShowPopupCreateTemplate] = useState({show: false});
    const [showPopupUpdateTemplate, setShowPopupUpdateTemplate] = useState({show: false});

    const columns = [
        { field: 'title', headerName: 'Titre', flex: 1.5, valueGetter: (params) => params.row.title },
        { field: 'actions', type: "actions", flex: 0.4, getActions: (params) => [
            <GridActionsCellItem
                icon={<FontAwesomeIcon icon={faEdit} size="1x" />}
                onClick={() => setShowPopupUpdateTemplate({ show: true, id: params.row.id })}
                label="Modifier"
            />,
            <GridActionsCellItem
                icon={<FontAwesomeIcon icon={faTrash} size="1x" />}
                onClick={() => setShowPopupConfirmDelete({ show: true, id: params.row.id })}
                label="Supprimer"
            />
        ] }
    ];

    useEffect(() => {
        handleGetData();
    }, []);

    const handleGetData = () => {
        getListTemplate().then(res => {
            setListTemplate({
                loading: false,
                data: res.template ?? []
            });
        });
    }  

    const handleDelete = () => {
        deleteTemplate(showPopupConfirmDelete.id).then(() => {
            handleGetData();
        });
    }

    return (
        <div className="list">

            <HeaderList title="Templates sections" icon={faTableColumns}>
                <Button variant="contained" onClick={() => setShowPopupCreateTemplate({ show: true })}>Créer un template</Button>
            </HeaderList>

            <Breadcrumb
                links={[
                    { label: "Templates" },
                    { label: "Templates sections" }
                ]}
            />

            <DataGridPro
                autoHeight
                rows={listTemplate.data}
                columns={columns}
                pageSize={20}
                className="table"
                loading={listTemplate.loading}
                pagination
                pageSizeOptions={[20, 50, 100]}
                slots={{
                    toolbar: CustomToolbar,
                }}
            />

            { showPopupCreateTemplate.show &&
                <Popup
                    closePopup={() => setShowPopupCreateTemplate({ show: false })}
                    customContent={true}
                >
                    <CreateTemplate closePopup={() => setShowPopupCreateTemplate({ show: false })} />
                </Popup>
            }

            { showPopupUpdateTemplate.show &&
                <Popup
                    closePopup={() => setShowPopupUpdateTemplate({ show: false })}
                    customContent={true}
                >
                    <UpdateTemplate closePopup={() => setShowPopupUpdateTemplate({ show: false })} id={showPopupUpdateTemplate.id} />
                </Popup>
            }

            { showPopupConfirmDelete.show &&
                <Popup
                    closePopup={() => setShowPopupConfirmDelete({ show: false })}
                    customContent={true}
                >
                    <ConfirmDelete
                        msg={"ce template"}
                        handleDelete={handleDelete}
                    />
                </Popup>
            }

        </div>
    )
}