import { TextField, Autocomplete, createFilterOptions } from "@mui/material";
import { Controller } from "react-hook-form";

export const FormAutocomplete = (props) => {

    const {
        name, control, label, rules, options, clearable, loading,
        renderOption = (props, option) =>
            <li {...props} key={option.id}>
                {option.label}
            </li>,
        isOptionEqualToValue = (option, newValue) => option.id === newValue.id,
        getOptionLabel = option => option.label,
        disabled,
        helperText,
        multiple,
        disableCloseOnSelect
    } = props;

    const filterOptions = createFilterOptions({
		matchFrom: 'any',
		limit: 500,
	});

    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field: { onChange, value }, fieldState: { error } }) =>
                <Autocomplete
                    options={Array.isArray(options) ? options : []}
                    disableClearable={!clearable}
                    fullWidth
                    openOnFocus
                    selectOnFocus
                    blurOnSelect={!disableCloseOnSelect}
                    disableCloseOnSelect={disableCloseOnSelect}
                    className="autocomplete"
                    value={
                        multiple ?
                            Array.isArray(value) ? value : []
                        : value ?? null
                    }
                    loading={loading}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            label={label}
                            error={!!error}
                            helperText={helperText}
                        />
                    }
                    renderOption={renderOption}
                    onChange={(_, data) => {
                        onChange(data);
                        return data;
                    }}
                    isOptionEqualToValue={isOptionEqualToValue}
                    getOptionLabel={getOptionLabel}
                    filterOptions={filterOptions}
                    size="small"
                    disablePortal
                    ListboxProps={{ style: { maxHeight: "175px" } }}
                    disabled={disabled}
                    multiple={multiple}
                />
            }
        />
    );
};