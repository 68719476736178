import { useState } from 'react';
import './addImgFromGallery.scss';
import { Button } from '@mui/material';
import { Popup } from '../Popup/Popup';
import { Medias } from '../../pages/Medias/Medias';

export const AddImgFromGallery = ({ chooseImg, value, label }) => {

    const [showPopupAddMedia, setShowPopupAddMedia] = useState({ show: false });

    return (
        <div className='add-img-from-gallery'>

            <label className='title'>{label}</label>

            {value ? <>
                <div className="upload-img">
                    <img
                        src={value.path}
                        alt={value.alt}
                    />
                    {/* {( !rules || (rules && !rules.required) ) && */}
                        <span className="remove-media" onClick={() => chooseImg(null)}>×</span>
                    {/* } */}
                </div>

                <label>{value.title}</label>
            </>
            :
                <label>{"Aucune image sélectionnée"}</label>     
            }   

            <Button
                onClick={() => setShowPopupAddMedia({ show: true })}
                variant="contained"
                disableElevation
            >
                Choisir une image
            </Button>

            { showPopupAddMedia.show &&
                <Popup
                    closePopup={() => setShowPopupAddMedia({ show: false })}
                    customContent={true}
                >
                    <Medias
                        isPopupSelect
                        chooseImg={chooseImg}
                    />
                </Popup>
            }
            
        </div>
    )
}