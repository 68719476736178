import { useState, useEffect } from "react";
import { getListUser, deleteUser } from '../../store/index.service';
import { useNavigate } from "react-router-dom";
import { HeaderList } from '../../components/HeaderList/HeaderList';
import { Breadcrumb } from '../../components/Breadcrumb/Breadcrumb';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import { DataGridPro, GridActionsCellItem, GridToolbarContainer, GridToolbarFilterButton } from '@mui/x-data-grid-pro';
import { Popup } from "../../components/Popup/Popup";
import { ConfirmDelete } from "../../components/Popup/content/ConfirmDelete";
import { Button } from "@mui/material";
import { CreateUser } from "../../components/Popup/createUser/CreateUser";
import { UpdateUser } from "../../components/Popup/createUser/UpdateUser";

const CustomToolbar = () => {
    return (
        <GridToolbarContainer>
            <GridToolbarFilterButton />
        </GridToolbarContainer>
    );
}

export const User = () => {

    const navigate = useNavigate();
    const [listUser, setListUser] = useState({loading: true, data: []});
    const [showPopupCreateUser, setShowPopupCreateUser] = useState({show: false});
    const [showPopupUpdateUser, setShowPopupUpdateUser] = useState({show: false});
    const [showPopupConfirmDelete, setShowPopupConfirmDelete] = useState({show: false});

    const columns = [
        { field: 'email', headerName: 'Email', flex: 1.5 },
        { field: 'firstName', headerName: 'Prénom', flex: 1.5 },
        { field: 'lastName', headerName: 'Nom', flex: 1.5 },
        { field: 'role', headerName: 'Role', flex: 1.5, valueGetter: (params) => params.row.role[0] },
        { field: 'actions', type: "actions", flex: 0.4, getActions: (params) => [
            <GridActionsCellItem
                icon={<FontAwesomeIcon icon={faEdit} size="1x" />}
                onClick={() => setShowPopupUpdateUser({ show: true, id: params.row.id })}
                label="Modifier"
            />,
            <GridActionsCellItem
                icon={<FontAwesomeIcon icon={faTrash} size="1x" />}
                onClick={() => setShowPopupConfirmDelete({ show: true, id: params.row.id })}
                label="Supprimer"
            />
        ] }
    ];

    useEffect(() => {
        handleGetData();
    }, []);

    const handleGetData = () => {
        getListUser().then(res => {
            setListUser({
                loading: false,
                data: res.user ?? []
            });
        });
    }  

    const handleDelete = () => {
        deleteUser(showPopupConfirmDelete.id).then(() => {
            handleGetData();
        });
    }

    return (
        <div className="list">

            <HeaderList title="Utilisateur" icon={faUser}>
                <Button variant="contained" onClick={() => setShowPopupCreateUser({ show: true })}>Créer un utilisateur</Button>
            </HeaderList>

            <Breadcrumb
                links={[
                    { label: "Utilisateur"}
                ]}
            />

            <DataGridPro
                autoHeight
                rows={listUser.data}
                columns={columns}
                pageSize={20}
                className="table"
                loading={listUser.loading}
                pagination
                pageSizeOptions={[20, 50, 100]}
                slots={{
                    toolbar: CustomToolbar,
                }}
            />

            { showPopupCreateUser.show &&
                <Popup
                    closePopup={() => setShowPopupCreateUser({ show: false })}
                    customContent={true}
                >
                    <CreateUser
                        closePopup={() => setShowPopupCreateUser({ show: false })}
                        refreshData={() => handleGetData()}
                    />
                </Popup>
            }

            { showPopupUpdateUser.show &&
                <Popup
                    closePopup={() => setShowPopupUpdateUser({ show: false })}
                    customContent={true}
                >
                    <UpdateUser
                        closePopup={() => setShowPopupUpdateUser({ show: false })}
                        refreshData={() => handleGetData()}
                        id={showPopupUpdateUser.id}
                    />
                </Popup>
            }

            { showPopupConfirmDelete.show &&
                <Popup
                    closePopup={() => setShowPopupConfirmDelete({ show: false })}
                    customContent={true}
                >
                    <ConfirmDelete
                        msg={"cette utilisateur"}
                        handleDelete={handleDelete}
                    />
                </Popup>
            }

        </div>
    )
}