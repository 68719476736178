export const authHeader = async() => {
  return new Promise((resolve, reject) => {
    
    const token = localStorage.getItem("token");
    
    if(token) 
      resolve(token);
    else 
      reject({ response: { status: 401 } });
  });
};