import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Button } from '@mui/material';
import { FormButtonSubmit } from "../../Form/FormButtonSubmit";
import { FormAutocomplete } from "../../Form/FormAutocomplete";
import { getListSection, addSectionToPage } from '../../../store/index.service';

export const AddSection = ({ handleClosePopup, refreshData, id }) => {
    
    const { handleSubmit, control } = useForm();
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [listSection, setListSection] = useState({ loading: true, data: [] });

    useEffect(() => {
        getListSection().then(res => {
            setListSection({
                loading: false,
                data: res ?? []
            });
        });

    }, []);

    const onSubmit = data => {

        setLoading(true);

        const formData = new FormData();
        
        for (const [key, value] of Object.entries(data)) {
            if(key === "idSection" && value)
                formData.append(key, value.id);

            //formData.append(key, value);
        }

        addSectionToPage(formData, id)
            .then(() => {
                refreshData();
                handleClosePopup();
            })
            .catch(err => {
                console.error(err);
                setLoading(false);

                if(err.response.status === 409)
                    setErrorMsg(err.response.data.result);
                else
                    setErrorMsg("Une erreur est survenue");
            })
    }

    return (
        <div className='popupCreateHorseQuick'>

            <h2>Ajouter une section</h2>

            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form">
                    
                    <div className="row-form">
                        <FormAutocomplete
                            name="idSection"
                            control={control}
                            label="Section"
                            options={listSection.data ?? []}
                            loading={listSection.loading}
                            getOptionLabel={el => el.template}
                            renderOption={(props, option) =>
                                <li {...props} key={option.id}>
                                    {option.fr.title.data} - {option.template}
                                </li>
                            }
                        />
                    </div>

                </div>

                <div className="btn-container">

                    <Button 
                        variant="text"
                        color="grey"
                        size="small"
                        className="cancel"
                        onClick={handleClosePopup}
                    >
                        Annuler
                    </Button>

                    <FormButtonSubmit loading={loading} />

                </div>
            </form>
        </div>
    )
}