import { config } from '../config';
import axios from 'axios';
import { authHeader } from '../authHeader';

export const getListSection = async () => {
    const result = await axios(config.baseUrl + 'api/v1/admin/section', {});
    return(result.data.data);
}

export const getOneSection = async (id) => {
    const result = await axios(config.baseUrl + 'api/v1/admin/section/' + id , {});
    return(result.data.data);
}

export const createSection = (data, lang) => {
    return new Promise((resolve, reject) => {
        try {
            axios.create({
                baseURL: config.baseUrl,
                headers: {
                    'Content-Type': 'application/json' ,
                    "Accept-Language": lang
                }
            }).post('api/v1/admin/section', data)
            .then(function (response) {
                resolve(JSON.stringify(response.data));    
            })
            .catch(function (error) {
                reject(error);
            });

        } catch (error) {
            reject(error);
        }
    });
}

export const updateSection = async (data, id, lang, idPage) => {
    return await authHeader()
    .then(token => {
        return axios.create({
            baseURL: `${config.baseUrl}${config.apiBase}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
                "Accept-Language": lang
            }
        }).post(`/admin/section/${id}/${idPage}`, data);
    });
}


export const deleteSection = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios.create({
                baseURL: config.baseUrl,
                headers: {
                    'Content-Type': 'application/json' 
                }
            }).delete('api/v1/admin/section/' + id, {})
            .then(function (response) {
                resolve(JSON.stringify(response.data));    
            })
            .catch(function (error) {
                reject(error);
            });

        } catch (error) {
            reject(error);
        }
    });
}

export const updateStatusSection = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios.create({
                baseURL: config.baseUrl,
                headers: {
                    'Content-Type': 'application/json' 
                }
            }).put('api/v1/admin/section/' + id + '/status', {})
            .then(function (response) {
                resolve(JSON.stringify(response.data));    
            })
            .catch(function (error) {
                reject(error);
            });

        } catch (error) {
            reject(error);
        }
    });
}

export const addField = (data, id) => {
    return new Promise((resolve, reject) => {
        try {
            axios.create({
                baseURL: config.baseUrl,
                headers: {
                    'Content-Type': 'application/json' 
                }
            }).post('api/v1/admin/field/new/' + id , data)
            .then(function (response) {
                resolve(JSON.stringify(response.data));    
            })
            .catch(function (error) {
                reject(error);
            });

        } catch (error) {
            reject(error);
        }
    });
}

export const updateOrderItem = (data, id) => {
    return new Promise((resolve, reject) => {
        try {
            axios.create({
                baseURL: config.baseUrl,
                headers: {
                    'Content-Type': 'application/json' 
                }
            }).post('api/v1/admin/item/' + id + '/order', data)
            .then(function (response) {
                resolve(JSON.stringify(response.data));    
            })
            .catch(function (error) {
                reject(error);
            });

        } catch (error) {
            reject(error);
        }
    });
}

