import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Button } from '@mui/material';
import { FormAutocomplete } from "../../Form/FormAutocomplete";
import { FormButtonSubmit } from '../../Form/FormButtonSubmit';
import { FormInputText } from '../../Form/FormInputText';
import { FIELD_TYPES } from '../../../helpers';
import { addField, addFieldForm, getListItemForm } from '../../../store/index.service';

export const AddField = ({ handleClosePopup, id, type }) => {

    const { handleSubmit, control, watch } = useForm();
    const [loading, setLoading] = useState(false);
    const [listItemForm, setListItemForm] = useState({loading: true, data: []});

    const onSubmit = data => {

        setLoading(true);

        const { fieldKey, fieldLabel, fieldType } = data;

        const postData = {
            [fieldKey]: {
                data: fieldLabel,
                type: fieldType.type
            }
        }

        if (type === "section") {
            addField(postData, id);
        } else {
            addFieldForm(postData, id);
        }

        setTimeout(() => setLoading(false), 1000);

        handleClosePopup();
    }

    useEffect(() => {
        handleGetData();
    }, []);

    const handleGetData = () => {
        getListItemForm().then(res => {
            setListItemForm({
                loading: false,
                data: res.data ?? []
            });
        });
    }  

    return (
        <div className='popupCustom'>

            <h2>Ajouter un champ</h2>

            <form onSubmit={handleSubmit(onSubmit)}>

                <div className="row-form">

                    <FormAutocomplete
                        name={"fieldType"}
                        label={"Type de champ"}
                        control={control}
                        rules={{ required: true }}
                        options={FIELD_TYPES}
                    />

                </div>

                {watch("fieldType") && watch("fieldType").type === "select" &&
                    <div className="row-form">

                        <FormAutocomplete
                            name={"optionSelect"}
                            label={"Options de la liste"}
                            control={control}
                            rules={{ required: true }}
                            options={listItemForm.data}
                            getOptionLabel={el => el.title}
                            renderOption={(props, option) =>
                                <li {...props} key={option.title}>
                                    {option.title}
                                </li>
                            }
                        />

                    </div>
                }

                <div className="row-form">

                    <FormInputText
                        name={"fieldLabel"}
                        label={"Nom du champ"}
                        control={control}
                        rules={{ required: true }}
                    />

                </div>

                <div className="row-form">

                    <FormInputText
                        name={"fieldKey"}
                        label={"Identifiant du champ"}
                        control={control}
                        placeholder="ex : subtitle"
                        rules={{ required: true }}
                    />

                </div>

                <div className="btn-container">

                    <Button 
                        variant="text"
                        color="grey"
                        size="small"
                        className="cancel"
                        onClick={handleClosePopup}
                    >
                        Annuler
                    </Button>

                    <FormButtonSubmit loading={loading}>
                        Valider
                    </FormButtonSubmit>

                </div>
            
            </form>

        </div>
    )
}