import { useState } from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { ListItemButton, ListItemIcon, ListItemText, ListItem, Collapse, List } from "@mui/material";

export const NavItem = (props) => {

    const { item } = props;
    const { link, icon, text, subMenu } = item;

    const [open, setOpen] = useState(false);

    return (
    <>
        <ListItemButton
            component={!subMenu ? NavLink : "div"}
            to={link}
            onClick={() => subMenu && setOpen(!open)}
            className="list-item"
        >

            <ListItemIcon sx={{ minWidth: 40 }}>
                <FontAwesomeIcon icon={icon} size="lg" />
            </ListItemIcon>

            <ListItemText primary={text} />

            {subMenu &&
                <FontAwesomeIcon icon={open ? faChevronUp : faChevronDown} size="sm" />
            }

        </ListItemButton>

        {subMenu &&
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>

                    {Array.isArray(subMenu) && subMenu.map(el =>
                        <ListItem
                            sx={{ pl: 7 }}
                            component={NavLink}
                            to={el.link}
                            key={el.text}
                            className="list-item"
                            dense
                        >
                            <ListItemText primary={el.text} />
                        </ListItem>   
                    )}

                </List>
            </Collapse>
        }
    </>)
}
