import { useState } from 'react';
import { Button, Menu, MenuItem } from '@mui/material';

export const PopMenu = (props) => {

    const { label, btnId, menuId, items = [] } = props;

    const [anchorEl, setAnchorEl] = useState(null);

    const openMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = !!anchorEl;

    return (
        <>
            <Button
                id={btnId}
                aria-controls={open ? menuId : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={openMenu}
            >
                {label}
            </Button>
            <Menu
                id={menuId}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': btnId,
                }}
                disableScrollLock
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right"
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                {items.map((el, i) =>
                    
                    <MenuItem
                        key={i}
                        onClick={() => {
                            el.handleClick();
                            handleClose();
                        }}
                    >
                        {el.label}
                    </MenuItem>
                )}
            </Menu>

        </>
    )
}