import { useContext } from 'react';
import './header.scss';
import { StoreContext } from '../../store/store';
import { PopMenu } from './PopMenu';

import frFlag from "../../assets/images/icons/fr.svg";
import enFlag from "../../assets/images/icons/en.svg";


export const Header = () => {
    
    const { setLoggedIn, user = {}, lang, setLang } = useContext(StoreContext);

    const flags = {
        fr: frFlag,
        en: enFlag
    }

    return (
        <header>

            <h1>Mediawan</h1>

            <div className='menus'>

                <PopMenu
                    label={<img src={flags[lang]} alt={lang.toUpperCase()} className='flag-icon' />}
                    btnId="lang-menu-trigger"
                    menuId="lang-menu"
                    items={[
                        {
                            label: "Français",
                            handleClick: () => setLang("fr")
                        },
                        {
                            label: "Anglais",
                            handleClick: () => setLang("en")
                        }
                    ]}
                />

                <PopMenu
                    label={`${user.firstName} ${user.lastName}`}
                    btnId="user-menu-trigger"
                    menuId="user-menu"
                    items={[
                        {
                            label: "Déconnexion",
                            handleClick: () => setLoggedIn(false)
                        }
                    ]}
                />

            </div>

        </header>
    )
}