import { useState, useEffect, useContext } from "react";
import { useForm } from "react-hook-form";
import { FormInputText } from './FormInputText';
import { FormAutocomplete } from "./FormAutocomplete";
import { FormButtonSubmit } from "./FormButtonSubmit";
import { updatePage, getOnePage } from '../../store/index.service';
import { StoreContext } from "../../store/store";
import { NotificationManager } from "react-notifications";

export const FormPage = (props) => {

    const { lang } = useContext(StoreContext);

    const { handleSubmit, control, setValue } = useForm();

    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    useEffect(() => {

        getOnePage(props.id).then(res => {
            setValue("title", res[lang].title.data);
            setValue("metaTitle", res[lang].metaTitle.data);
            setValue("metaContent", res[lang].metaContent.data);
        });


    }, []);

    const onSubmit = data => {
        setLoading(true);
        const formData = new FormData();
        
        for (const [key, value] of Object.entries(data))
            formData.append(key, value);


        updatePage(formData, props.id, lang)
            .then(() => {
                NotificationManager.success("", "Page modifiée");
            })
            .catch(err => {
                NotificationManager.error("", "Une erreur est survenue");

                if (err.response.status === 409)
                    setErrorMsg(err.response.data.result);
                else
                    setErrorMsg("Une erreur est survenue");
            })
            .finally(() => setLoading(false))
    }

    return (
        <div className="form-page">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form">
                    
                    <div className="row-form">
                        <FormInputText
                            name="title"
                            label="Titre"
                            control={control}
                            defaultValue="Titre"
                            rules={{ required: true }}
                        />
                    </div>

                    <div className="row-form">
                        <FormInputText
                            name="metaTitle"
                            label="Titre SEO"
                            control={control}
                            defaultValue="Titre SEO"
                            rules={{ required: true }}
                        />
                    </div>

                    <div className="row-form">
                        <FormInputText
                            name="metaContent"
                            label="Description SEO"
                            control={control}
                            defaultValue="Description SEO"
                            rules={{ required: true }}
                        />
                    </div>

                </div>

                <div className="btn-container">
                    <FormButtonSubmit fullWidth loading={loading} />
                </div>
            </form>
        </div>
    );
};