import { config } from '../config';
import axios from 'axios';
import { authHeader } from '../authHeader';

export const getListUser = async () => {
    const result = await axios(config.baseUrl + 'api/v1/admin/user', {});
    return(result.data);
}

export const getOneUser = async (id) => {
    const result = await axios(config.baseUrl + 'api/v1/admin/user/' + id , {});
    return(result.data.user[0]);
}

export const createUser = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios.create({
                baseURL: `${config.baseUrl}${config.apiBase}`,
                headers: {
                    'Content-Type': 'application/json' 
                }
            }).post('admin/user', data)
            .then(function (response) {
                resolve(JSON.stringify(response.data));    
            })
            .catch(function (error) {
                reject(error);
            });

        } catch (error) {
            reject(error);
        }
    });
}

export const updateUser = async (data, id) => {
    return await authHeader()
    .then(token => {
        return axios.create({
            baseURL: `${config.baseUrl}${config.apiBase}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            }
        }).post(`admin/user/${id}`, data);
    });
}

export const deleteUser = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios.create({
                baseURL: config.baseUrl,
                headers: {
                    'Content-Type': 'application/json' 
                }
            }).delete('api/v1/admin/user/' + id, {})
            .then(function (response) {
                resolve(JSON.stringify(response.data));    
            })
            .catch(function (error) {
                reject(error);
            });

        } catch (error) {
            reject(error);
        }
    });
}

export const updateStatusUser = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios.create({
                baseURL: config.baseUrl,
                headers: {
                    'Content-Type': 'application/json' 
                }
            }).put('api/v1/user/' + id + '/status', {})
            .then(function (response) {
                resolve(JSON.stringify(response.data));    
            })
            .catch(function (error) {
                reject(error);
            });

        } catch (error) {
            reject(error);
        }
    });
}

export const updateStatusDressage = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios.create({
                baseURL: config.baseUrl,
                headers: {
                    'Content-Type': 'application/json' 
                }
            }).put('api/v1/user/' + id + '/dressage', {})
            .then(function (response) {
                resolve(JSON.stringify(response.data));    
            })
            .catch(function (error) {
                reject(error);
            });

        } catch (error) {
            reject(error);
        }
    });
}

export const updateStatusCso = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios.create({
                baseURL: config.baseUrl,
                headers: {
                    'Content-Type': 'application/json' 
                }
            }).put('api/v1/user/' + id + '/cso', {})
            .then(function (response) {
                resolve(JSON.stringify(response.data));    
            })
            .catch(function (error) {
                reject(error);
            });

        } catch (error) {
            reject(error);
        }
    });
}
