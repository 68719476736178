import { useState } from 'react';
import './visionneuse.scss';

// import closeIcon from "../../assets/icons/close.svg";
// import arrow from "../../assets/icons/arrow-left.svg";

export const Visionneuse = ({ index, handleClose = () => {}, data = [] }) => {

    const [currentIdx, setCurrentIdx] = useState(index);

    const handleArrow = (e, idx) => {
        e.stopPropagation();

        if(idx === -1 && prevDisabled)
            return;

        if(idx === 1 && nextDisabled)
            return;

        setCurrentIdx(currentIdx + idx);
    }

    const prevDisabled = currentIdx <= 0;
    const nextDisabled = currentIdx >= data.length - 1;

    return (
        <div className={`visionneuse`} onClick={handleClose}>

            {/* <img
                src={closeIcon}
                className='visionneuse-close'
                alt="x"
            />

            <img
                src={arrow}
                className='visionneuse-arrow prev'
                alt="prev"
                onClick={(e) => handleArrow(e, -1)}
                disabled={prevDisabled}
            /> */}
            
            <img id="visionneuse-img" src={data[currentIdx] ?? ""} alt={""} />

            {/* <img
                src={arrow}
                className='visionneuse-arrow next'
                alt="next"
                onClick={(e) => handleArrow(e, 1)}
                disabled={nextDisabled}
            /> */}

        </div>
    )
}