import ClipLoader from "react-spinners/ClipLoader";
import { Button } from "@mui/material";

export const FormButtonSubmit = (props) => {

    const { loading, fullWidth, variant } = props;

    return (
        <Button 
            variant={variant ?? "contained"}
            disableElevation
            fullWidth={fullWidth}
            type="submit"
        >
            {props.children ?? "Enregistrer"}
            <ClipLoader
                color={"#fff"}
                loading={loading}
                size={20}
                cssOverride={{ marginLeft: 5 }}
            />
        </Button>
    );
};