import './navigation.scss';
import { List } from '@mui/material';
import { faGaugeHigh, faNewspaper, faPhotoFilm, faTableColumns, faUser, faFolderTree } from '@fortawesome/free-solid-svg-icons'
import { NavItem } from './NavItem';

export const Navigation = () => {
    
    const dataMenu = [
        {
            icon: faGaugeHigh,
            text: 'Dashboard',
            link: '/'
        },
        {
            icon: faNewspaper,
            text: 'Pages',
            link: '/page'
        },
        {
            icon: faPhotoFilm,
            text: 'Médias',
            link: '/medias'
        },
        {
            icon: faTableColumns,
            text: 'Templates',
            subMenu: [
                {
                    text: 'Header',
                    link: '/template/header'
                },
                {
                    text: 'Footer',
                    link: '/template/footer'
                },
                {
                    text: 'Templates sections',
                    link: '/template/template'
                },
                {
                    text: 'Sections',
                    link: '/template/section'
                },
            ]
        },
        {
            icon: faUser,
            text: 'Utilisateurs',
            link: '/user'
        },
    ]

    return (
        <nav className="navigation">

            <List
                sx={{ width: '100%' }}
                component="nav"
            >
                { dataMenu.map(el => 
                    <NavItem item={el} key={el.text} />
                )}
            </List>
        </nav>
    )
}
