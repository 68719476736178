import { config } from '../config';
import axios from 'axios';
import { authHeader } from '../authHeader';

export const getOneConfig = async (id) => {
    const result = await axios(config.baseUrl + 'api/v1/admin/config/' + id, {});
    return(result.data);
}

export const updateConfig = async (data, id) => {
    return await authHeader()
    .then(token => {
        return axios.create({
            baseURL: `${config.baseUrl}${config.apiBase}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            }
        }).post(`admin/config/${id}`, data);
    });
}



