import { config } from '../config';
import axios from 'axios';
import Cookies from 'js-cookie';
import { authHeader } from '../authHeader';

export const signIn = async (data) => {
    return await axios.create({
        baseURL: config.baseUrl,
        headers: {
            'Content-Type': 'application/json'
        }
    }).post(`/auth`, data);
}

export const logOut = async () => {

    return await new Promise((resolve) => {
        
        Cookies.remove('loggedIn');
        localStorage.removeItem("token");
        localStorage.removeItem("idUser");
        
        resolve();
    });
}

export const getUserConnected = async () => {
    return await authHeader()
    .then(token => {
        return axios.create({
            baseURL: `${config.baseUrl}${config.apiBase}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            }
        }).get(`/connected/user`);
    })
}

export const requestPassword = async (data) => {
    return await axios.create({
        baseURL: `${config.baseUrl}${config.apiBase}`,
        headers: {
            'Content-Type': 'application/json'
        }
    }).post(`/request-password`, data);
}

export const changePassword = async (data, token) => {
    return await axios.create({
        baseURL: `${config.baseUrl}${config.apiBase}`,
        headers: {
            'Content-Type': 'application/json'
        }
    }).post(`/change-password`, { ...data, token });
}