import { useState, useEffect, useContext } from "react";
import { getListPage, deletePage } from '../../store/index.service';
import { useNavigate } from "react-router-dom";
import { HeaderList } from '../../components/HeaderList/HeaderList';
import { Breadcrumb } from '../../components/Breadcrumb/Breadcrumb';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faNewspaper, faEdit, faTrash, faEye } from '@fortawesome/free-solid-svg-icons'
import { DataGridPro, GridActionsCellItem, GridToolbarContainer, GridToolbarFilterButton } from '@mui/x-data-grid-pro';
import { Popup } from "../../components/Popup/Popup";
import { ConfirmDelete } from "../../components/Popup/content/ConfirmDelete";
import { Button } from "@mui/material";
import { CreatePage } from "../../components/Popup/createPage/CreatePage";
import { StoreContext } from "../../store/store";

const CustomToolbar = () => {
    return (
        <GridToolbarContainer>
            <GridToolbarFilterButton />
        </GridToolbarContainer>
    );
}

export const Page = () => {

    const navigate = useNavigate();

    const { lang } = useContext(StoreContext);

    const [listPage, setListPage] = useState({loading: true, data: []});
    const [showPopupCreatePage, setShowPopupCreatePage] = useState({show: false});
    const [showPopupConfirmDelete, setShowPopupConfirmDelete] = useState({show: false});

    const columns = [
        { field: 'title', headerName: 'Titre', flex: 1.5, valueGetter: (params) => params.row[lang].title.data },
        { field: 'metaTitle', headerName: 'Méta title', flex: 1.5, valueGetter: (params) => params.row[lang].metaTitle.data },
        { field: 'metaContent', headerName: 'Méta déscription', flex: 1.5, valueGetter: (params) => params.row[lang].metaContent.data },
        { field: 'actions', type: "actions", flex: 0.4, getActions: (params) => [
            <GridActionsCellItem
                icon={<FontAwesomeIcon icon={faEdit} size="1x" />}
                onClick={() => navigate(`/page/${params.row.id}/view`)}
                label="Modifier"
            />,
            <GridActionsCellItem
                icon={<FontAwesomeIcon icon={faTrash} size="1x" />}
                onClick={() => setShowPopupConfirmDelete({ show: true, id: params.row.id })}
                label="Supprimer"
            />
        ] }
    ];

    useEffect(() => {
        handleGetData();
    }, []);

    const handleGetData = () => {
        getListPage().then(res => {
            setListPage({
                loading: false,
                data: res.page ?? []
            });
        });
    }

    const handleDelete = () => {
        deletePage(showPopupConfirmDelete.id).then(() => {
            handleGetData();
        });
    }

    return (
        <div className="list">

            <HeaderList title="Page" icon={faNewspaper}>
                <Button variant="contained" onClick={() => setShowPopupCreatePage({ show: true })}>Créer une page</Button>
            </HeaderList>

            {/* <Breadcrumb
                links={[
                    { label: "Page" }
                ]}
            /> */}

            <DataGridPro
                autoHeight
                rows={listPage.data}
                columns={columns}
                pageSize={20}
                className="table"
                loading={listPage.loading}
                pagination
                pageSizeOptions={[20, 50, 100]}
                slots={{
                    toolbar: CustomToolbar,
                }}
            />

            { showPopupCreatePage.show &&
                <Popup
                    closePopup={() => setShowPopupCreatePage({ show: false })}
                    customContent={true}
                >
                    <CreatePage
                        closePopup={() => setShowPopupCreatePage({ show: false })}
                        refreshData={() => handleGetData()}
                    />
                </Popup>
            }

            { showPopupConfirmDelete.show &&
                <Popup
                    closePopup={() => setShowPopupConfirmDelete({ show: false })}
                    customContent={true}
                >
                    <ConfirmDelete
                        msg={"cette page"}
                        handleDelete={handleDelete}
                    />
                </Popup>
            }

        </div>
    )
}