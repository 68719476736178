import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Button } from '@mui/material';
import { FormAutocomplete } from "../../Form/FormAutocomplete";
import { FormButtonSubmit } from '../../Form/FormButtonSubmit';
import { FormInputText } from '../../Form/FormInputText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faNewspaper, faEdit, faTrash, faEye } from '@fortawesome/free-solid-svg-icons'
import { DataGridPro, GridActionsCellItem, GridToolbarContainer, GridToolbarFilterButton } from '@mui/x-data-grid-pro';
import { getOneItemForm } from '../../../store/index.service';

export const ListField = ({ handleClosePopup, item }) => {

    const { handleSubmit, control } = useForm();
    const [loading, setLoading] = useState(false);
    const [listField, setListField] = useState({loading: true, data: []});
    const [showPopupConfirmDelete, setShowPopupConfirmDelete] = useState({show: false});

    const columns = [
        { field: 'data', headerName: 'Data', flex: 1.5 },
        { field: 'type', headerName: 'Type', flex: 1.5 }
    ];

    const generateRows = (list = {}) => {
        let data = Object.values(list).map((val, i) => ({
            ...val,
            id: i
        }))
        return data;
    }

    useEffect(() => {
        handleGetData();
    }, []);

    const handleGetData = () => {
        getOneItemForm(item).then(res => {
            setListField({
                loading: false,
                data: res.data[0] ?? []
            });
        });
    }  

    return (
        <div className='popupCustom'>

            <DataGridPro
                autoHeight
                rows={generateRows(listField.data)}
                columns={columns}
                pageSize={20}
                className="table"
                loading={listField.loading}
            />
        </div>
    )
}