import { Controller } from "react-hook-form";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export const FormCKEditor = (props) => {

    const {
        name, control, label, rules
    } = props;

    return (
        <div className="form-group">

            <label>{label}</label>

            <Controller
                name={name}
                control={control}
                rules={rules}
                render={({ field: { onChange, value }, fieldState: { error } }) =>
                    <div className={error ? "error" : undefined}>
                        <CKEditor
                            editor={ClassicEditor}
                            data={value ?? ""}
                            onReady={editor => {}}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                onChange(data);
                            }}
                            onBlur={(event, editor) => {}}
                            onFocus={(event, editor) => {}}
                        />
                    </div>
                }
            />

        </div>
    );
};