import React, { useEffect, useRef } from 'react';
import './popup.scss';
import closeIcon from "../../assets/images/icons/close.svg";

export const Popup = ({ title, children, closePopup, customContent, top }) => {

    const popupRef = useRef();
    const containerRef = useRef();

    useEffect(() => {
        document.documentElement.classList.add("modal-open");
    }, []);

    // close popup menu when click outside
    useEffect(() => {

        function handleClickOutsidePopup(event) {
            if(containerRef.current && !containerRef.current.contains(event.target))
                handleClosePopup();
        }
        
        document.addEventListener("mousedown", handleClickOutsidePopup);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutsidePopup);
        };
    }, [containerRef]);

    const handleClosePopup = () => {

        popupRef.current.classList.add("closed");

        setTimeout(() => {
            closePopup();
            document.documentElement.classList.remove("modal-open");
        }, 450);
    }

    return (
        <div className={`popup ${top ? "top" : ""}`} ref={popupRef}>
            <div className='popup-container' ref={containerRef}>

                <div className='closePopupIcon' onClick={() => handleClosePopup()}>
                    <img src={closeIcon} alt="close" />
                </div>

                { title &&
                    <h4>{title}</h4>
                }

                <div className='popup-content'>
                    { !!customContent ? React.Children.map(children, child => {

                        if (React.isValidElement(child))
                            return React.cloneElement(child, { handleClosePopup });
                        
                        return child;
                    })
                    : children
                    }
                </div>

            </div>
        </div>
    )
}