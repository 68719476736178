import { TextField, InputAdornment } from "@mui/material";
import { Controller } from "react-hook-form";

export const FormInputText = (props) => {

    const { name, control, label, rules, type = "text", inputProps, endAdornment, helperText, disabled, placeholder } = props;

    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) =>
                <TextField
                    label={label}
                    fullWidth
                    size="small"
                    onChange={onChange}
                    error={!!error}
                    type={type}
                    value={value ?? ""}
                    helperText={helperText}
                    inputProps={inputProps}
                    InputProps={{
                        endAdornment: endAdornment ? <InputAdornment position="end">{endAdornment}</InputAdornment> : undefined,
                        placeholder
                    }}
                    disabled={disabled}
                />
            }
        />
    );
};