import { ToggleButtonGroup, ToggleButton } from "@mui/material";
import { Controller } from "react-hook-form";

export const FormToggle = (props) => {

    const {
        control,
        name,
        items = [],
        exclusive = true
    } = props;

    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value } }) =>
                <ToggleButtonGroup
                    color="primary"
                    value={value}
                    exclusive={exclusive}
                    onChange={(e, newValue) => {
                        if(newValue !== null)
                            onChange(newValue);
                    }}
                    size="small"
                >
                    {items.map(el =>
                        <ToggleButton value={el.value} key={el.value}>{el.label}</ToggleButton>
                    )}
                </ToggleButtonGroup>
            }
        />
    );
};