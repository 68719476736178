import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './headerList.scss';


export const HeaderList = (props) => {

    const { title, icon, children } = props;
    
    return (
        <div className="header-list">

            <div className="title">

                <div className="icon">
                    <FontAwesomeIcon icon={icon} />
                </div>

                <h1>{title}</h1>

            </div>

            <div className="buttons">

                {children}

            </div>

        </div>
    )
}