const numberWithCommas = (value) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

const FIELD_TYPES = [
    {
        id: 1,
        label: "Texte",
        type: "string"
    },
    {
        id: 2,
        label: "Éditeur de texte",
        type: "text"
    },
    {
        id: 3,
        label: "Image",
        type: "image"
    },
    {
        id: 4,
        label: "Sélection",
        type: "select"
    },
    {
        id: 5,
        label: "Liste d'item",
        type: "list"
    },
    {
        id: 6,
        label: "Checkbox",
        type: "checkbox"
    },
    {
        id: 7,
        label: "Integer",
        type: "integer"
    },
    {
        id: 8,
        label: "Button",
        type: "button"
    },
];

const blobToBase64 = (blob) => {
    return new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result.split(",")[1]);
        reader.readAsDataURL(blob);
    });
}

const generateMainForm = (dataForm) => {
    let data = {};

    for(const [key, value] of Object.entries(dataForm)) {
        if(value && value.type !== "image")
            Object.assign(data, { [key]: value });
    }

    return data;
}

const generateAsideForm = (dataForm) => {
    let data = {};

    for(const [key, value] of Object.entries(dataForm)) {
        if(value && value.type === "image")
            Object.assign(data, { [key]: value });
    }
    
    return data;
}
  
export {
    numberWithCommas,
    FIELD_TYPES,
    blobToBase64,
    generateMainForm,
    generateAsideForm
};