import './dashboard.scss';
import { faGaugeHigh } from '@fortawesome/free-solid-svg-icons';
import { HeaderList } from '../../components/HeaderList/HeaderList';

export const Dashboard = () => {
    return (
        <div className='dashboard'>
            <HeaderList title="Dashboard" icon={faGaugeHigh} />

            <div className='page-content'>

                <h2>Bienvenue sur le backoffice de Superprod !</h2>
                
                <p>
                    Vous pouvez modifier les contenus des pages de votre site ainsi que modifier l'ordre des sections et leur couleur de fond.<br/>
                    Pour la gestion de vos images, vous disposez d'une gallerie dans la page Médias.<br/>
                    <br/>
                    Pensez à bien enregistrer vos textes et images en français ET en anglais ! Vous pouvez changer la langue en cliquant sur le drapeau en haut à droite.<br/>
                    <br/>
                    Nous ne prenons en charge que les vidéos hébergées sur Vimeo. Quand une section demande un lien vidéo, il s'agit de la seconde partie des urls que Vimeo vous fournit lorsque vous partagez une vidéo.<br/>
                    Ex : https://player.vimeo.com/video/<strong>831954179?h=99734dc28d</strong><br/>
                    <br/>
                    N'hésitez pas à nous contacter en cas de question.<br/>
                    <br/>
                    - La team UXIA
                </p>

            </div>
        </div>
    )
}