import { config } from '../config';
import axios from 'axios';
import { authHeader } from '../authHeader';

export const getListItemForm = async () => {
    const result = await axios(config.baseUrl + 'api/v1/admin/itemForm', {});
    return(result.data);
}

export const getOneItemForm = async (item) => {
    const result = await axios(config.baseUrl + 'api/v1/admin/itemForm/' + item, {});
    return(result.data);
}

export const addFieldForm = (data, id) => {
    return new Promise((resolve, reject) => {
        try {
            axios.create({
                baseURL: config.baseUrl,
                headers: {
                    'Content-Type': 'application/json' 
                }
            }).post('api/v1/admin/field/item/new/' + id , data)
            .then(function (response) {
                resolve(JSON.stringify(response.data));    
            })
            .catch(function (error) {
                reject(error);
            });

        } catch (error) {
            reject(error);
        }
    });
}



