import { FormControlLabel, Switch } from "@mui/material";
import { FormInputText } from "./FormInputText";
import { Controller } from "react-hook-form";

export const FormButton = (props) => {

    const {
        control,
        statusName = "btn_status",
        textName = "btn_title",
        urlName = "btn_url"
    } = props;

    return (
        <div className="form-group button">

            <div className="label-container">

                <label>Bouton</label>

                <FormControlLabel
                    control={
                        <Controller
                            name={statusName}
                            control={control}
                            render={({ field: { onChange, value } }) =>
                                <Switch
                                    checked={!!value}
                                    onChange={onChange}
                                    size="small"
                                />
                            }
                        />
                    }
                />
            </div>

            <div className="btn-form">

                <FormInputText
                    name={textName}
                    label='Texte du bouton'
                    control={control}
                    rules={{ required: false }}
                />
                <FormInputText
                    name={urlName}
                    label='Lien du bouton'
                    control={control}
                    rules={{ required: false }}
                />
                
            </div>

        </div>
    );
};