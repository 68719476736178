import React from "react";
import { Link } from "react-router-dom";
import './breadcrumb.scss';


export const Breadcrumb = (props) => {

    const { links = [] } = props;

    return (
        <div className="breadcrumb">
            <div className="breadcrumb-item">

                {/* <Link to="/">Dashboard</Link> */}

                {links.map((el, i) => <React.Fragment key={el.label}>
                    {i > 0 && <>
                        &nbsp;{">"}&nbsp;
                    </>}
                    {el.to ?
                        <Link to={el.to}>{el.label}</Link>
                    :
                        <span>{el.label}</span>
                    }
                </React.Fragment>)}

            </div>
        </div>
    )
}