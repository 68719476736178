import { useState, useEffect } from 'react';
import './medias.scss';
import { useForm } from 'react-hook-form';
import { getListMedia, deleteMedia, updateMedia } from '../../store/index.service';
import { useNavigate } from 'react-router-dom';
import { HeaderList } from '../../components/HeaderList/HeaderList';
import { faPhotoFilm } from '@fortawesome/free-solid-svg-icons';
import { Button } from '@mui/material';
import { FormInputText } from '../../components/Form/FormInputText';
import { FormButtonSubmit } from '../../components/Form/FormButtonSubmit';
import { Popup } from '../../components/Popup/Popup';
import { ConfirmDelete } from '../../components/Popup/content/ConfirmDelete';
import { Visionneuse } from '../../components/Visionneuse/Visionneuse';
import { AddMedia } from '../../components/Popup/content/AddMedia';
import ReactPaginate from 'react-paginate';
import { TextField, InputAdornment } from "@mui/material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';


export const Medias = ({ isPopupSelect, chooseImg = () => {}, handleClosePopup = () => {} }) => {

	const navigate = useNavigate();
    const { handleSubmit, control, setValue } = useForm();
    const [selectedImg, setSelectedImg] = useState(false);
    const [showPopupConfirmDelete, setShowPopupConfirmDelete] = useState({ show: false });
    const [showPopupAddMedia, setShowPopupAddMedia] = useState({ show: false });
    const [vision, setVision] = useState({ display: false, index: 0 });
	const [errorMsg, setErrorMsg] = useState("");
	const [loading, setLoading] = useState(false);
	const [page, setPage] = useState(0);
	const [search, setSearch] = useState("");
    const [listMedia, setListMedia] = useState({loading: true, data: []});
    const offset = 24;

    useEffect(() => {
		handleGetData();
    }, []);

    const onSubmit = data => {
        setLoading(true);

        const formData = new FormData();
        
        for (const [key, value] of Object.entries(data))
            formData.append(key, value);

        formData.append("langue", "fr");

        updateMedia(formData, selectedImg.id)
            .then(() => {
                navigate('/medias');
            })
            .catch(err => {
                console.error(err);
                setLoading(false);

                if(err.response.status === 409)
                    setErrorMsg(err.response.data.result);
                else
                    setErrorMsg("Une erreur est survenue");
            })
    }

	const handleGetData = () => {
		getListMedia().then(res => {
			setListMedia({
				loading: false,
				data: res.media ?? []
			});
    	});
    }  

    const handleDelete = () => {
		deleteMedia(selectedImg.id).then(() => {
            handleGetData();
        });
    }

    const selectMedia = (media) => {
        setSelectedImg(media);
        setValue("title", media.title);
        setValue("alt", media.alt);
    }

    const filteredData = () => {
        return listMedia.data.filter(el => el.title.toLowerCase().includes(search.toLowerCase()))
    }

    return (
        <div className="medias-gallery">

            <HeaderList title="Médias" icon={faPhotoFilm}>
                <Button variant="contained" onClick={() => setShowPopupAddMedia({ show: true })}>Ajouter un média</Button>
            </HeaderList>

            <div className='section-container'>

                <TextField
                    size="small"
                    onChange={(e) => setSearch(e.target.value)}
                    value={search}
                    InputProps={{
                        endAdornment: <InputAdornment position="end"><FontAwesomeIcon icon={faMagnifyingGlass} /></InputAdornment>,
                        sx: {
                            minWidth: "400px",
                            backgroundColor: "#fff"
                        },
                        placeholder: "Recherche"
                    }}
                    sx={{
                        margin: "10px 0 20px"
                    }}
                />

            </div>

            <div className='section-container'>

                <div className='gallery'>

                    <div className='gallery-container'>

                        {filteredData()
                            .reverse()
                            .slice(page * offset, page * offset + offset)
                            .map(el =>
                                <img
                                    src={`${el.path}`}
                                    className={selectedImg && selectedImg.id === el.id ? "selected" : ""}
                                    alt={el.alt}
                                    onClick={() => selectMedia(el)}
                                    key={el.id}
                                />
                            )
                        }

                    </div>

                    <ReactPaginate
                        className="pagination"
                        breakLabel="..."
                        nextLabel=">"
                        onPageChange={currentPage => setPage(currentPage.selected)}
                        pageRangeDisplayed={5}
                        pageCount={filteredData().length / offset}
                        previousLabel="<"
                        renderOnZeroPageCount={null}
                    />

                </div>

                <div className='aside-right'>

                    {selectedImg ?
                        <form onSubmit={handleSubmit(onSubmit)}>

                            <div className='row-form'>
                                {isPopupSelect &&
                                    <Button variant='contained' fullWidth onClick={() => {
                                        chooseImg(selectedImg);
                                        handleClosePopup();
                                    }}>
                                        Sélectionner
                                    </Button>
                                }
                            </div>

                            <div className="row-form">
                                <FormInputText
                                    name={"title"}
                                    label={"Nom"}
                                    control={control}
                                    rules={{ required: true }}
                                />
                            </div>

                            <div className="row-form">
                                <FormInputText
                                    name={"alt"}
                                    label={"Alt"}
                                    control={control}
                                />
                            </div>

                            <div className="btn-container">
                                <FormButtonSubmit variant={isPopupSelect ? "outlined" : "contained"} fullWidth loading={false}>Modifier</FormButtonSubmit>
                                <Button variant='outlined' fullWidth onClick={() => setVision({ display: true, index: 0 })}>Voir</Button>
                                <Button variant='outlined' color="error" fullWidth onClick={() => setShowPopupConfirmDelete({ show: true })}>Supprimer</Button>
                            </div>

                        </form>
                    :
                        <p>Aucune image sélectionnée</p>
                    }

                </div>

            </div>

            { showPopupAddMedia.show &&
                <Popup
                    closePopup={() => setShowPopupAddMedia({ show: false })}
                    customContent={true}
                >
                    <AddMedia refreshData={handleGetData} />
                </Popup>
            }

            { showPopupConfirmDelete.show &&
                <Popup
                    closePopup={() => setShowPopupConfirmDelete({ show: false })}
                    customContent={true}
                >
                    <ConfirmDelete msg={"ce média"} handleDelete={handleDelete} />
                </Popup>
            }

            {vision.display &&
                <Visionneuse
                    index={vision.index}
                    data={[selectedImg.path]}
                    handleClose={() => setVision({
                        display: false,
                        index: 0
                    })}
                    noNav
                />
            }

        </div>
    )
}