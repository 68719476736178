import { useState, createContext } from 'react';

export const StoreContext = createContext();

export const StoreProvider = ({ children }) => {
    
    const [loggedIn, setLoggedIn] = useState(!!localStorage.getItem("token"));
    
    const [user, setUser] = useState({});

    const [lang, setLang] = useState("fr");

    return (
        <StoreContext.Provider value={{
            loggedIn, setLoggedIn,
            user, setUser,
            lang, setLang
        }}>
            { children }
        </StoreContext.Provider>
    );
}