import { config } from '../config';
import axios from 'axios';
import { authHeader } from '../authHeader';

export const getListPage = async () => {
    const result = await axios(config.baseUrl + 'api/v1/admin/pages', {});
    return(result.data);
}

export const getOnePage = async (id) => {
    const result = await axios(config.baseUrl + 'api/v1/admin/pages/' + id , {});
    return(result.data.page[0]);
}

export const createPage = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios.create({
                baseURL: config.baseUrl,
                headers: {
                    'Content-Type': 'application/json' 
                }
            }).post('api/v1/admin/pages', data)
            .then(function (response) {
                resolve(JSON.stringify(response.data));    
            })
            .catch(function (error) {
                reject(error);
            });

        } catch (error) {
            reject(error);
        }
    });
}

export const updatePage = async (data, id, lang) => {
    return await authHeader()
    .then(token => {
        return axios.create({
            baseURL: `${config.baseUrl}${config.apiBase}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
                "Accept-Language": lang
            }
        }).post(`/admin/pages/${id}`, data);
    });
}

export const deletePage = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios.create({
                baseURL: config.baseUrl,
                headers: {
                    'Content-Type': 'application/json' 
                }
            }).delete('api/v1/admin/pages/' + id, {})
            .then(function (response) {
                resolve(JSON.stringify(response.data));    
            })
            .catch(function (error) {
                reject(error);
            });

        } catch (error) {
            reject(error);
        }
    });
}

export const updateStatusPage = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios.create({
                baseURL: config.baseUrl,
                headers: {
                    'Content-Type': 'application/json' 
                }
            }).put('api/v1/admin/status/pages/' + id, {})
            .then(function (response) {
                resolve(JSON.stringify(response.data));    
            })
            .catch(function (error) {
                reject(error);
            });

        } catch (error) {
            reject(error);
        }
    });
}
