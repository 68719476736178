import React from 'react';
import ReactDOM from 'react-dom/client';
import './app.scss';
import { App } from './App';
import reportWebVitals from './reportWebVitals';
import { StoreProvider } from './store/store';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <StoreProvider>
      <App />
    </StoreProvider>
);

reportWebVitals();
