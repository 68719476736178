import { FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import { Controller } from "react-hook-form";

export const FormCheckbox = (props) => {

    const { data = [], control } = props;

    return (
        <FormGroup>
            {data.map(el =>
                <FormControlLabel
                    label={el.label}
                    key={el.name}
                    control={
                        <Controller
                            name={el.name}
                            control={control}
                            rules={el.rules}
                            render={({ field: { onChange, value } }) =>
                                <Checkbox
                                    size="small"
                                    onChange={onChange}
                                    checked={!!value}
                                />
                            }
                        />
                    }
                />
            )}
        </FormGroup>
    );
};